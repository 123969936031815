<template>
  <!--
    Displays the differences between an old and a new configuration file.
  -->
  <pre
    ref="preComp"
    :class="!isConfiguration ? 'f-col' : ''"
  />
</template>

<script>

import * as diff from 'diff'

export default {
  name: 'AuditLogCompare',
  props: {
    oldVal: {
      type: String,
      required: false,
      default: " "
    },
    newVal: {
      type: String,
      required: false,
      default: " "
    },
    isConfiguration: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted () {
    this.getDiff();
  },
  methods: {
    getDiff () {
      var fragment = document.createDocumentFragment();
      var dif = diff.diffLines(this.oldVal, this.newVal);
      dif.forEach((part) => {
        var color = part.added ? 'green' :
          part.removed ? 'red' : 'black';
        var span = document.createElement('span');
        span.style.color = color;
        span.appendChild(document
          .createTextNode(part.value));
        fragment.appendChild(span);
      });
    this.$refs.preComp.appendChild(fragment);
    }
  }
}
</script>

<style>
pre {
  white-space: pre-line;
}
.f-col {
  display:flex; 
  flex-direction:column;
}
</style>
